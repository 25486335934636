import './App.css'
import { useEffect, useState } from "react";
import './css/bootstrap.min.css';
import { Stack, Table, Button, ButtonGroup } from 'react-bootstrap';
import axios from "axios";
import ImprestTable from './ImprestTable';
import AttendanceTable from './AttendanceTable';
//import { Button, Table} from react-bootstrap;
function AppSecond() {
  const [datasource, setDataSource] = useState([]);
  //const [isclicked, setclicked] = useState(false);
  const [imprest, setImprest] = useState(false);
  const [attend, setAttend] = useState(false);
  const [user, setUser] = useState("");
  useEffect(() => {
    var data = JSON.stringify({
      "username": "rajat"
    });
    var token = localStorage.getItem("token");
    var config = {
      method: 'get',
      url: 'https://imprest.sos.org.in/api/users/',
      headers: {
        'Authorization': 'Bearer ' + token
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setDataSource(response.data)
        //console.log(datasource.at(0).balance)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  const DisplayData = datasource.map(
    (info) => {
      //localStorage.setItem("username", info.username);
      return (
        <tr>
          <td>{info.username}</td>
          <td>{info.balance}</td>
          <td><Button onClick={() => {

            setUser(info.username)
            setImprest(true)
          }}>Imprest</Button></td>

          <td><Button onClick={() => {
            setUser(info.username)
            setAttend(true)
          }}>Attendance</Button></td>
        </tr>
      )

    }
  );
  return (

    <div>
      <div>
        <Table className="table-bordered table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Balance</th>
              <th>#</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>


            {DisplayData}

          </tbody>
        </Table>
      </div>
      <div>
        {(user !== '' && imprest) ? <div className="details"><ImprestTable user={user} /></div> : console.log("Not logged in")}
      </div>
      <div>
        <div>
          {(user !== '' && attend) ? <div className="attendance"><AttendanceTable user={user} /></div> : console.log("nothing.....")}
        </div>
      </div>
    </div>
  );
}

export default AppSecond;
