import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './css/bootstrap.min.css';
import {useEffect, useState} from "react";
import {Table, Button, Container, Form, InputGroup, Card} from 'react-bootstrap';
import axios from "axios";
import { CSVLink } from 'react-csv';
function AttendanceTable({ user }) {
    let currentDate = new Date();
    const [attendance, setAttendance] = useState([]);
    const [search, setSearch] = useState("");
    var token = localStorage.getItem("token");
    var filtered_data_attendance = [];

    useEffect(() => {
      var data = JSON.stringify({
        "username": user
        });
      var config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://imprest.sos.org.in/api/attend/getAttendLog',
          headers: { 
              'Authorization': 'Bearer '+token, 
              'Content-Type': 'application/json'
      },
        data : data
      };
      axios(config)
      .then(function (response) {
          console.log(response.status)
          var res = response.data.log.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
          //console.log(response.data.log.length)
          for(let i=0; i<response.data.log.length; i++){
            //console.log(response.data.log[i])
            if(response.data.log[i].out == 1 ){
              response.data.log[i].out = "office"
            }else{
              response.data.log[i].out = "out"
            }
          }
          setAttendance(response.data.log)
      })
      .catch(function (error) {
          console.log(error);
      });
    }, [user]);        
    
    const DisplayData= attendance.map(
        (info)=>{
          //localStorage.setItem("username", info.username);
          return(
            <tr>
              <td>{info.username}</td>
              <td>{info.out}</td>
              <td>{info.datetime}</td>
              <td>{info.lat}</td>
              <td>{info.long}</td>
            </tr>
          )
        }
      );
      const columns = [{
        dataField: 'username',
        text: 'User'
      }, {
        dataField: 'out',
        text: 'location'
      }, {
        dataField: 'datetime',
        text: 'datetime'
      }, {
        dataField: 'lat',
        text: 'lat'
      },{
        dataField: 'long',
        text: 'long'
      }
        ]
    
      const paginationOptions = {
        sizePerPage: 5,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true
      };
    {attendance.filter((item)=>{
      return search.toLowerCase () === ''? item: item.datetime.toLocaleLowerCase().includes(search)
      }).map(
        (info) => {
          var length = filtered_data_attendance.length;
          filtered_data_attendance[length] = info;
      }
    )}
    const headers = [
      {label: "Username", key:"username"},
      {label: "Office", key:"out"},
      {label: "Datetime", key: "datetime"},
      {label: "Latitude", key:"lat"},
      {label: "Longitude", key:"long"}
    ]
    const customStyles = {
      cell: (row, rowIndex, columnIndex, colIndex) => {
        if(row.out === 1){
          return {
            color: 'blue'
          };
        }else{
            return {
            color: 'black'
          };
        }
      }
    };    
    const csvReport = {
      filename: user+"_attendance"+(currentDate.getDate().toString())+"-"+(currentDate.getMonth()+1).toString()+"-"+currentDate.getFullYear().toString()+".csv",
      headers: headers,
      data: filtered_data_attendance
    }
return(
   <div>
    <Container>
      <Form>
        <InputGroup className='my-3'>
          <Form.Control onChange={(e) => setSearch(e.target.value)} placeholder='Search your keyword'/>
        </InputGroup>
      </Form>
      <BootstrapTable
          keyField='id'
          data={filtered_data_attendance}
          columns={columns}
          pagination={paginationFactory(paginationOptions)}
          rowStyle={customStyles.cell}
        />
    </Container>
    <CSVLink {...csvReport}>Export to CSV For Attendance data</CSVLink>     
   </div>     
  
)
}
export default AttendanceTable;