import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ModalImage from "react-modal-image";
import { CSVLink } from 'react-csv';
import './css/bootstrap.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Table, Button, Container, Form, InputGroup, Card} from 'react-bootstrap';
import axios from "axios";
import {useEffect, useState} from "react";
function ImprestTable({ user }) {
    let currentDate = new Date();
    const [search, setSearch] = useState("");
    var filtered_data = [];
    const [datasource, setDataSource] = useState([]);
    var token = localStorage.getItem("token");
    if(datasource.length === 0 || datasource[0].username !==user ){
      var data = JSON.stringify({
        "username": user
      });
      var config = {
        method: 'post',
        url: 'https://imprest.sos.org.in/api/transac/transLog',
        headers: { 
          'Authorization': 'Bearer '+token, 
          'Content-Type': 'application/json'
        },
        data : data
      };
      axios(config)
      .then(function (response) {
        var res = response.data.log.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
        setDataSource(response.data.log)
        //console.log("updated response based on datetime.....")
        //console.log(res);
      })
      .catch(function (error) {
        console.log(error);
      });      
    } else {
      console.log("data already in datasource, so not making post request.")
      //console.log(datasource[0].username)
      //console.log(user)
    }
    const headers = [
      {label: "Username", key:"username"},
      {label: "Amount", key:"amount"},
      {label: "Datetime", key:"datetime"},
      {label: "Remark", key:"remark"},
      {label: "Balance", key:"balance"},
      {label: "Out", key:"out"}
    ]

    const customStyles = {
      cell: (row, rowIndex, columnIndex, colIndex) => {
        if(row.out == 'out'){
          return {
            color: 'red'
          };
        }else{
            return {
            color: 'green'
          };
        }
      }
    };
    const CustomFormatter = (cell, row) => {
      if(row.out == 'out'){
        return <ModalImage
        small = {"https://imprest.sos.org.in/api/static/imageIcon.jpeg"}
        medium = {"https://imprest.sos.org.in/api/static/"+row.tid+".jpg"}
        alt="Image"
        />;
      }
      else{
        return <h1/>
      }
      
    };
    const columns = [{
      dataField: 'username',
      text: 'User'
    }, {
      dataField: 'amount',
      text: 'amount'
    }, {
      dataField: 'datetime',
      text: 'datetime'
    }, {
      dataField: 'remark',
      text: 'remark'
    },{
      dataField: 'balance',
      text: 'balance'
    }, {
      dataField: 'tid',
      text: 'tid'
    }, ,{
      dataField: '#',
      text: '#',
      formatter: CustomFormatter
    } 
      ]
  
    const paginationOptions = {
      sizePerPage: 5,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };
    let csv_data = []
    {datasource.filter((item)=>{
      return search.toLowerCase () === ''? item: item.datetime.toLocaleLowerCase().includes(search)
      }).map(
        (info) => {
          var length = filtered_data.length;
          filtered_data[length] = info;
          csv_data[length] = info;
          if(info.for){
            csv_data[length].amount = csv_data[length].amount;
            csv_data[length].out = "out"
          } else{
            csv_data[length].out = "in"
            csv_data[length].amount = csv_data[length].amount;
          }

          
          //console.log(filtered_data.length);
      }
    )}
    const csvReport = {
      filename: user+"_imprest"+(currentDate.getDate().toString())+"-"+(currentDate.getMonth()+1).toString()+"-"+currentDate.getFullYear().toString()+".csv",
      headers: headers,
      data: csv_data
    }  
    return (
    <div>
      
      <Container>
        <Form>
          <InputGroup className='my-3'>
            <Form.Control onChange={(e) => setSearch(e.target.value)} placeholder='Search your keyword'/>
          </InputGroup>
        </Form>
        <BootstrapTable
          keyField='id'
          data={filtered_data}
          columns={columns}
          pagination={paginationFactory(paginationOptions)}
          rowStyle={customStyles.cell}
        />  
      </Container> 
      <CSVLink {...csvReport}>Export to CSV</CSVLink>
    </div>  

    
    );
  }
  
  export default ImprestTable;